import { jsonp } from '@/common/request/jsonp'
import { server } from '@/config'
import i18n from '@/i18n'
import { computed, shallowRef } from 'vue'
export type Content = {
  type: number; // type 1: 标题 2: 段落 3: 图片
  val: string;
}
export type PageData = {
    title: string;
    content: Content[];
}

export const useGoogleTranslate = (originContent: PageData) => {
  const translated = shallowRef()
  const isOrigin = shallowRef(true)
  const progress = shallowRef(false)

  const content = computed(() => {
    return isOrigin.value ? originContent : translated.value
  })

  const translate = () => {
    if (isOrigin.value && !translated.value) {
      progress.value = true
      translated.value = JSON.parse(JSON.stringify(originContent))
      const reqArr = []
      const title = callapi(translated.value.title, ['title', 0])
      reqArr.push(title)
      for (let i = 0; i < translated.value.content.length; i++) {
        const item = translated.value.content[i]
        reqArr.push(callapi(item.val, ['content', i]))
      }
      Promise.all(reqArr).then(res => {
        progress.value = false
        res.forEach(item => {
          if (item.keyName[0] === 'title') {
            translated.value[item.keyName[0]] = item.translatedText
          } else {
            translated.value[item.keyName[0]][item.keyName[1]].val = item.translatedText
          }
        })

        isOrigin.value = !isOrigin.value
      })
    } else {
      isOrigin.value = !isOrigin.value
    }
  }

  const callapi = async (str: string, keyName: [string, number]) => {
    const resp = await jsonp(
      server.translate,
      {
        target: i18n.locale.value,
        q: encodeURIComponent(str),
      },
    )
    const res = (resp as {
        data: {
          translatedText: string
        }
    }).data.translatedText
    return {
      translatedText: res,
      keyName,
    }
  }

  return {
    progress,
    isOrigin,
    translate,
    content,
  }
}
