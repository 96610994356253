
import { computed, shallowRef, defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import { useGoogleTranslate } from '@/pages/help/lesson'
import page from '@/pages/help/lesson.json' // type 1: 标题 2: 段落 3: 图片

export default defineComponent({
  name: 'Help',
  components: {
    PageWithHeader,
    Button,
    Pic,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const pageData = shallowRef()
    pageData.value = page[props.index]

    const { translate, content, progress, isOrigin } = useGoogleTranslate(pageData.value)
    const formatData = computed(() => content.value)

    return {
      progress,
      translate,
      isOrigin,
      formatData,
    }
  },
})
